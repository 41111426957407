import { FC } from "react";
import { SurveyResponseScreen } from "features/SurveyResponse";

type SurveyReponsePageProps = {};

const SurveyReponsePage: FC<SurveyReponsePageProps> = () => {
  return <SurveyResponseScreen />;
};

export default SurveyReponsePage;
