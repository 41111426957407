import { FC, useState, useEffect } from "react";
import { LoadEntityData } from "api/getSurveyList";
import { EntityData } from "types/survey";


import styled from "styled-components";
import CustomInput from "components/input/Input";
import CustomButton from "components/button/Button";
import useWindowSize from "utils/use-window-size";
import { useParams } from "react-router-dom";
import { getSurveysByUniqueIdAndIdEntity } from "api/getSurveyList";

type AccessUsingUniqueIdScreenProps = {};

const AccessUsingUniqueIdScreen: FC<AccessUsingUniqueIdScreenProps> = () => {
  const [entityData, setData] = useState<EntityData | null>(null);
  // const [surveysData, setSurveyData] = useState<SurveyData | null>(null);

  const params = useParams();
  const entity_id = params.entity_id;

  useEffect(() => {
    if (entity_id !== "" && entity_id !== undefined && entity_id !== null) {
      LoadEntityData(entity_id).then(x => {
        if (x?.data !== undefined) {
          setData(x.data)
        }
      })
    }
  }, [entity_id]);

  const SurveysByUniqueId = (entity_id: number, UniqueId: string) => {
    return getSurveysByUniqueIdAndIdEntity(entity_id, UniqueId).then((x) => {
      if (x?.data === true) {
        window.location.href = "/surveys-unique/" + identifier + "/" + entity_id
      } else {
        errorsetIdentifier("Invalid unique identifier")
      }
    });
  }

 


  const [identifier, setIdentifier] = useState<string>("");
  const [erroridentifier, errorsetIdentifier] = useState<string>("");

  const { width } = useWindowSize();

  const changeIdentifier = (e) => {
    setIdentifier(e.target.value)
    if (e.target.value === "") {
      errorsetIdentifier("")
    }
  }

  return (
    <Container>
      {entityData && <Content>
        <ImageWrapper>
          <StyledImg src="/assets/access-code-img.png" alt="Error" />
          {width < 1025 && (
            <BottomLogoWrapper>
              <BottomLogo src="/assets/er-logo-pri_no-borders-light.png" />
            </BottomLogoWrapper>
          )}
        </ImageWrapper>
        <FormWrapper>
          <Form>
            <Title>{entityData.workspace_idNavName}: {entityData.name}</Title>
            <Description>View your surveys</Description>
            <Text>Easily find surveys awaiting your input. Dive in and share your thoughts!</Text>
            <Label>Your feedback will be analyzed securely and confidentially.</Label>
            <Text>Please enter your unique identifier:</Text>
            <StyledInput
              name="identifier"
              id="identifier"
              placeholder="Enter your unique identifier"
              variant="standard"
              error={erroridentifier !== ""}
              helperText={erroridentifier}
              autoComplete="off"
              value={identifier}
              onChange={changeIdentifier}
            />
            <ViewButton disabled={identifier === ""} onClick={() => {
              if (identifier === "") {
                return errorsetIdentifier("Cannot be null")
              } else {
                errorsetIdentifier("")
              }
              SurveysByUniqueId(entityData.id, identifier)
            }} variant="contained">View Surveys</ViewButton>
          </Form>
          {width > 1024 && (
            <BottomLogoWrapper>
              <BottomLogo src="/assets/er-logo-pri_no-borders-light.png" />
            </BottomLogoWrapper>
          )}
        </FormWrapper>
      </Content>}
    </Container>
  );
};

export default AccessUsingUniqueIdScreen;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1074px;
  min-height: 698px;
  background-color: var(--colorNeutralBackground1);
  padding: 122px 60px 48px 60px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const ImageWrapper = styled.div``;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 402px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: 600px;
    margin-bottom: 40px;
  }
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Description = styled.h2`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 10px 0px 0px 0px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin: 12px 0px 0px 0px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground2);
  margin: 20px 0px 8px 0px;
`;

const ViewButton = styled(CustomButton)`
  height: 36px !important;
  margin-top: 40px !important;
`;

const StyledInput = styled(CustomInput)`
  width: 100%;
  margin-top: 10px !important;
`;

const StyledImg = styled.img`
  width: 382px;
  height: 454px;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

const BottomLogoWrapper = styled.div`
  width: 100%;

  @media (max-width: 1024px) {
    margin: 40px 0px 20px 0px;
    display: flex;
    justify-content: center;
  }
`;

const BottomLogo = styled.img`
  width: 184px;
  height: 29px;
`;
