import { SurveyData, Entity } from "types/survey";
import { makeAutoObservable } from "mobx";
import { getSurveysByGuid, getSurveysByUniqueId, getEntityById } from "api/getSurveyList";

class Store {
  guid: string = ""
  entityId: number = 0
  surveysList: SurveyData[] = [];
  entity: Entity = null;
  isLoadingSurveyList = true;

  reviewsList = [];

  constructor() {
    makeAutoObservable(this);
  }
  
  LoadSurveysByGuid(guid: string) {
    return getSurveysByGuid(guid).then((x) => {
      if (x?.data !== undefined) {
        this.setSurveys(x.data);
      }
    });
  }
  
  LoadSurveysByUniqueId(UniqueId: string, entityId: number) {
    return getSurveysByUniqueId(UniqueId, entityId).then((x) => {
      if (x?.data !== undefined) {
        this.setSurveys(x.data);
      }
    });
  }
  
  getEntityById(entityId: number) {
    return getEntityById(entityId).then((x) => {
      if (x?.data !== undefined) {
        this.setEntity(x.data);
      }
    });
  }

  setEntity = (data: Entity) => {
    this.entity = data;
  };
  
  setSurveys = (data: SurveyData[]) => {
    this.surveysList = data;
  };

  setGuid = (guid: string) => {
    this.guid = guid;
  };

}

const store = new Store();

export default store;
