import { ReactElement } from "react"

const ToggleOff = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1477F8"
        d="M17 7H7a5 5 0 100 10h10a5 5 0 100-10zM7 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm0 0"
      ></path>
    </svg>
  )
}

export default ToggleOff
