import { FC } from "react";
import { Outlet } from "react-router-dom";

import styled from "styled-components";

type AppWrapperProps = {};

const AppWrapper: FC<AppWrapperProps> = () => {
  return (
    <AppContent>
      <Outlet />
    </AppContent>
  );
};

export default AppWrapper;

const AppContent = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: relative;
`;
