import { SurveyData } from "types/survey";
import { makeAutoObservable } from "mobx";
import { getSurveyAsyncByGuid, AnswerRecipient, SaveAnswersTemporarily } from "api/getSurveyList";
import { GetAllLanguagesForSurvey } from "api/useGetAllLanguagesForSurvey";
import { UserAttribute, dictionary_language } from "./ResponseEditor/sharedTypes";

class Store {
  guid: string = null;
  SurveyData: SurveyData = null;
  Attributes: UserAttribute[] = []
  
  allLanguages: dictionary_language[] = []
  survey_languages: dictionary_language[] = []
  survey_languages_iso: string[] = []
  selected_language: string = "en"
  selected_language_id: number = 0

  loading: boolean = false;
  openAlert: boolean = false;
  success: boolean = false;
  validated: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  LoadSurvey() {
    if (this.guid === "" || this.guid === null || this.guid === undefined) {
      return;
    }
    return getSurveyAsyncByGuid(this.guid).then((x) => {
      if (x?.data !== undefined) {
        this.setSurveyData(x.data);
        this.setAttributeData(x.data.attributes)
      }
    });
  }

  GetLanguages() {
    return GetAllLanguagesForSurvey().then((x) => {
      if (x?.data !== undefined) {
        this.setDictionaryLanguages(x.data);
      }
    });
  }
  clearStore = () => {
    this.guid = null;
    this.SurveyData = null;
    this.Attributes = null
    this.allLanguages = []
    this.survey_languages = []
    this.survey_languages_iso = []
    this.selected_language = "en"
    this.selected_language_id = 0
  }

  AnswerSubmitted(survey: string) {
    return AnswerRecipient({survey_answer: survey, guid: this.guid});
  }

  SaveAnswersTemporarily(survey: string) {
    return SaveAnswersTemporarily({survey_answer: survey, guid: this.guid});
  }

  setGuid = (guid: string) => {
    this.guid = guid;
  };

  setSurveyData = (data: SurveyData) => {
    this.SurveyData = data;
  };

  
  setDictionaryLanguages = (data: dictionary_language[]) => {
    this.allLanguages = data;
    store.setLanguages()
  };

  setSurveyIsoLanguages = (data: string[]) => {
    this.survey_languages_iso = data;
    store.setLanguages()
  };

  setLanguages = () => {
    this.survey_languages = this.allLanguages.filter(x => this.survey_languages_iso.includes(x.iso))
    let en_lang = this.allLanguages.filter(x => x.iso == this.selected_language)
    if(en_lang.length !== 0){
      this.selected_language_id = en_lang[0].id
      this.selected_language = en_lang[0].iso
    }
  }
  
  setAttributeData = (data: UserAttribute[]) => {
    this.Attributes = data;
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  changeLanguage = (id: number) => {
    this.selected_language_id = id
    let lang = this.allLanguages.filter(x => x.id == id)
    if(lang.length === 0){
      this.selected_language = "en"
    }else{
      this.selected_language = lang[0].iso
    }
    this.SurveyData = null
    this.LoadSurvey()
  }
}

const store = new Store();

export default store;
