import { Serializer, QuestionRatingModel, settings, surveyLocalization } from "survey-core";
import { ENPS_QUESTION_TYPE, METRIC_PROPERTY_NAME, QUESTION_IDENTIFIER_PROPERTY_NAME, REQUIRED_IF_ANSWER_PROPERTY_NAME, REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME, VISIBLE_IF_ANSWER_PROPERTY_NAME, VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME } from "./surveyJsConstants";
import { QUESTION_SETTING_CATEGORY, QUESTION_TITLE_DISPLAY_NAME_VALUE } from "./surveyJsConstants";
import { customRequiredIfProperties, customVisibleIfProperties } from "./propertyOverrides";

export default class ENPSQuestionModel extends QuestionRatingModel {
  public getType(): string {
    return ENPS_QUESTION_TYPE;
  }

  public get visibleIfAnswer(): string {
    return this.getPropertyValue(VISIBLE_IF_ANSWER_PROPERTY_NAME);
  }

  set visibleIfAnswer(val) {
    this.setPropertyValue(VISIBLE_IF_ANSWER_PROPERTY_NAME, val);
  }

  public get visibleIfEmployeeSegment(): string {
    return this.getPropertyValue(VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME);
  }

  set visibleIfEmployeeSegment(val) {
    this.setPropertyValue(VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME, val);
  }

  public get requiredIfAnswer(): string {
    return this.getPropertyValue(REQUIRED_IF_ANSWER_PROPERTY_NAME);
  }

  set requiredIfAnswer(val) {
    this.setPropertyValue(REQUIRED_IF_ANSWER_PROPERTY_NAME, val);
  }

  public get requiredIfEmployeeSegment(): string {
    return this.getPropertyValue(REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME);
  }

  set requiredIfEmployeeSegment(val) {
    this.setPropertyValue(REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME, val);
  }
}

Serializer.addClass(
  ENPS_QUESTION_TYPE,
  [
    {
      name: "title",
      displayName: QUESTION_TITLE_DISPLAY_NAME_VALUE,
      visibleIndex: 0,
      category: QUESTION_SETTING_CATEGORY,
    },
    {
      name: "showCommentArea:switch",
      layout: "row",
      visible: true,
      category: QUESTION_SETTING_CATEGORY
    },
    {
      name: "autoGenerate",
      category: "rateValues",
      default: true,
      choices: [true, false],
      visibleIndex: 4
    },
    {
      name: "rateCount:number",
      default: 10,
      category: "rateValues",
      visibleIndex: 3,
      onSettingValue: (obj: any, val: any): any => {
        if (val < 2) return 2;
        if (val > settings.ratingMaximumRateValueCount && val > obj.rateValues.length) return settings.ratingMaximumRateValueCount;
        return val;
      },
    },
    {
      name: "rateValues:itemvalue[]",
      baseValue: function (): string[] {
        return surveyLocalization.getString("choices_Item");
      },
      category: "rateValues",
      visibleIf: function (obj: any): boolean {
        return !obj.autoGenerate;
      },
      visibleIndex: 5
    },
    {
      name: "rateMin:number",
      default: 0,
      category: "rateValues",
      onSettingValue: (obj: any, val: any): any => {
        return val > obj.rateMax - obj.rateStep ? obj.rateMax - obj.rateStep : val;
      },
      visibleIf: function (obj: any): boolean {
        return !!obj.autoGenerate;
      },
      visibleIndex: 6
    },
    {
      name: "rateMax:number",
      category: "rateValues",
      default: 10,
      onSettingValue: (obj: any, val: any): any => {
        return val < obj.rateMin + obj.rateStep ? obj.rateMin + obj.rateStep : val;
      },
      visibleIf: function (obj: any): boolean {
        return !!obj.autoGenerate;
      },
      visibleIndex: 7
    },
    {
      name: "rateStep:number",
      default: 1,
      minValue: 0.1,
      category: "rateValues",
      onSettingValue: (obj: any, val: any): any => {
        if (val <= 0) val = 1;
        if (val > obj.rateMax - obj.rateMin)
          val = obj.rateMax - obj.rateMin;
        return val;
      },
      visibleIf: function (obj: any): boolean {
        return !!obj.autoGenerate;
      },
      visibleIndex: 8
    },
    {
      name: "displayMode",
      category: "rateValues",
      default: "auto",
      choices: ["auto", "buttons", "dropdown"],
      visibleIndex: 20
    },
    {
      name: METRIC_PROPERTY_NAME,
      category: "general",
    },
    {
      name: QUESTION_IDENTIFIER_PROPERTY_NAME,
      category: "general",
    },
    {
      name: "minRateDescription",
      alternativeName: "mininumRateDescription",
      serializationProperty: "locMinRateDescription",
      visibleIndex: 17,
      default: "Not at all likely"
    },
    {
      name: "maxRateDescription",
      alternativeName: "maximumRateDescription",
      serializationProperty: "locMaxRateDescription",
      visibleIndex: 18,
      default: "Extremely likely"
    },
    ...customVisibleIfProperties,
    ...customRequiredIfProperties,
  ],
  function () {
    return new ENPSQuestionModel("");
  },
  "question"
);
