import { ReactElement } from "react"

const LinearScale = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <filter
          id="alpha"
          width="100%"
          height="100%"
          x="0%"
          y="0%"
          filterUnits="objectBoundingBox"
        >
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          ></feColorMatrix>
        </filter>
        <image
          id="image7"
          width="24"
          height="24"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAABmJLR0QA/wD/AP+gvaeTAAAAIklEQVQ4jWP8/4eZgRqAiSqmjBo0atCoQaMGjRo0ahBFAABLOgIuppzipwAAAABJRU5ErkJggg=="
        ></image>
        <mask id="mask0">
          <g filter="url(#alpha)">
            <use xlinkHref="#image7"></use>
          </g>
        </mask>
        <clipPath id="clip1">
          <path d="M0 0H24V24H0z"></path>
        </clipPath>
        <g id="surface6" clipPath="url(#clip1)">
          <path
            fill="#1477F8"
            fillRule="evenodd"
            d="M19.5 9.5a2.502 2.502 0 00-2.29 1.5h-2.92A2.502 2.502 0 0012 9.5 2.502 2.502 0 009.71 11H6.79A2.502 2.502 0 004.5 9.5 2.502 2.502 0 002 12c0 1.379 1.121 2.5 2.5 2.5A2.502 2.502 0 006.79 13h2.92c.392.879 1.259 1.5 2.29 1.5a2.502 2.502 0 002.29-1.5h2.92c.392.879 1.259 1.5 2.29 1.5 1.379 0 2.5-1.121 2.5-2.5s-1.121-2.5-2.5-2.5zm0 0"
          ></path>
        </g>
      </defs>
      <use mask="url(#mask0)" xlinkHref="#surface6"></use>
    </svg>
  )
}

export default LinearScale
