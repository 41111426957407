import { useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import Lookup from "./LookUp";
import store from "./store";

import styled from "styled-components";
import SurveyResponseForm from "./SurveyResponseForm";
import { observer } from "mobx-react";
import { CONTACT_URL } from "constants/urls";

type SurveyResponseScreenProps = {};

const SurveyResponseScreen: FC<SurveyResponseScreenProps> = observer(() => {
  const params = useParams();
  const guid = params.guid;

  useEffect(() => {
    store.setGuid(guid);
    store.LoadSurvey();
    store.GetLanguages();
  }, [guid]);

  useEffect(() => {
    return () => store.clearStore();
  }, []);

  return (
    <Container>
      {store.SurveyData === null ? (
        <></>
      ) : (
        <>
          {store.SurveyData.status === "not_answered" && (
            <div>
              {store.survey_languages.length !== 0 && (
                <TopBarWrapper>
                  <Lookup
                    data={store.survey_languages}
                    value={store.selected_language_id}
                    errorText=""
                    error={false}
                    onChange={(e) => {
                      store.changeLanguage(e.target.value);
                    }}
                  />
                  <ContactLinkWrapper href={CONTACT_URL} target="_blank" rel="noreferrer">
                    Need help? Contact us
                  </ContactLinkWrapper>
                </TopBarWrapper>
              )}
              {store.SurveyData === null ? (
                ""
              ) : (
                <SurveyResponseForm
                  jsonForm={store.SurveyData.survey}
                  answers={store.SurveyData.answers}
                />
              )}
            </div>
          )}

          {store.SurveyData.status === "expired" && (
            <Message>The time to fill out the survey is over!</Message>
          )}

          {store.SurveyData.status === "answered" && (
            <Message>You have already completed this survey!</Message>
          )}

          {store.SurveyData.status === "not_correct_guid" && (
            <Message>You have landed on the wrong page!</Message>
          )}
        </>
      )}
    </Container>
  );
});

export default SurveyResponseScreen;

const Container = styled.div``;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #3e4450;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
`;

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ContactLinkWrapper = styled.a`
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
`;
