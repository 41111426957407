import { QuestionType } from "./sharedTypes"

export const QUESTION_SETTING_CATEGORY = "questionSetting"
export const QUESTION_TITLE_DISPLAY_NAME_VALUE = "Question"
export const ENPS_QUESTION_TYPE = QuestionType.enps
export const METRIC_PROPERTY_NAME = "metric"
export const QUESTION_IDENTIFIER_PROPERTY_NAME = "analyticsCode"
export const ORDERED_QUESTION_TYPES = [QuestionType.rating, QuestionType.enps, QuestionType.boolean, QuestionType.radiogroup, QuestionType.checkbox, QuestionType.comment]
export const SYNCABLE_RATING_LOCALIZABLE_PROPERTIES = [
  "commentText",
  "maxRateDescription",
  "minRateDescription",
]

export const EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE = "employeeSegmentLogicFieldQuestionType"
export const EMPLOYEE_SEGMENT_LOGIC_FIELD_TYPE = "employeeSegmentLogicField"
export const VISIBLE_IF_ANSWER_PROPERTY_NAME = "visibleIfAnswer"
export const VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME = "visibleIfEmployeeSegment"
export const REQUIRED_IF_ANSWER_PROPERTY_NAME = "requiredIfAnswer"
export const REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME = "requiredIfEmployeeSegment"
