import { FC } from "react";
import { AccessUsingUniqueIdScreen } from "features/AccessUsingUniqueId";

type AccessUsingUniqueIdPageProps = {};

const AccessUsingUniqueIdPage: FC<AccessUsingUniqueIdPageProps> = () => {
  return <AccessUsingUniqueIdScreen />;
};

export default AccessUsingUniqueIdPage;
