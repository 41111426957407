import { ReactElement } from "react"

const Promoter = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <image
          id="image13"
          width="24"
          height="24"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACvklEQVRIiaWVT0hUQRzHP+/tc3P9t5vtpqK22h6irh06lJ06dMhziJCK4EUI21uXICqKbiUVdtFDBylQ0g5JQixIGB4jClI3DWMVBLfnFuR7bzrszDo+F9LdgR9v3m/efL4z8+b3+xnsb4a0gNY3inwHIDRzfT4ArEOA1RgaTPmF9tSFCgA/3PRB/YJqhZ4ECs0vAEf19YmWD2JJIQMwJqan4+2JRLMwTWttbS0z2Nf3bXV1VUgRv5Cn76YCCAKV0kJALRAeGBho+rG5efuX4y7lPCF023a9zHrWfvJ4ZCQBROScGo0TlOw98BqgDoi8n5+/YDvuih9cRCj7aXHxqhSp84kcoRh8dm6uI+d69v/gmrlf0ukeKRKWuwlJbgEeBiLd3d0tB1l5Edt+OTl5tojILhyoX85k7pYAFzlPiA07NwHU6yKmdlNMoOJYfbSLElt1ddWVwWQyqjN1Aev56OgJywqcLFUAqOjp7T1P/vaYgKECygLMeCIRLwMOQDgcbkWLq0IgaUJlNVeIoOwa+wSWFxc3yhWwt7YyOtPUxoybyeSS67rliIg3U1MLusMAjpKPZgMILv/M3GpobOgvhf4n9/tDtLa6C9ghn4921A4K2fDBvTtPPc+zS+B7s+9mHrK3RgjIB0UMaASagPjbVOpazhPOYYLsczp9H4gDLZIVk2wi5I/puBxoBdpmUql+23EPko+8r+mVR5WVlW1ybiPQgIzoADLjye0E5NN8MTb2vSoUmjl1+kw0VBVqZ7eaqSa2bfvj68mJG5cudkw4jiPk2YNWHwzySUlVLRWBlu673NkZuT40dC4aizUHTNPMZrPrr8bHF54ND69LqCouhZ+rfAb5jKfApuwrMb/wnp9KvjSqivZXQnc0nxdgf6lUQoLdPCW0cXWcOnzf0agFKAEF8IN1Ya+ICd+74xPiH2wiDof5/uxGAAAAAElFTkSuQmCC"
        ></image>
      </defs>
      <g>
        <path
          fill="#1477F8"
          d="M24 12c0 6.629-5.371 12-12 12S0 18.629 0 12 5.371 0 12 0s12 5.371 12 12zm0 0"
        ></path>
        <use xlinkHref="#image13"></use>
        <path
          fill="#1477F8"
          fillRule="evenodd"
          d="M9.332 13.332a2.669 2.669 0 005.336 0zm0 0M9.09 12.496a1.137 1.137 0 000-2.273c-.63 0-1.137.507-1.137 1.136 0 .625.508 1.137 1.137 1.137zm6.012 0a1.138 1.138 0 000-2.273 1.137 1.137 0 000 2.273zm0 0"
        ></path>
      </g>
    </svg>
  )
}

export default Promoter
