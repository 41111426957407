import { ReactElement } from "react"

const Subject = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1477F8"
        fillRule="evenodd"
        d="M14 17H4v2h10zm6-8H4v2h16zM4 15h16v-2H4zM4 5v2h16V5zm0 0"
      ></path>
    </svg>
  )
}

export default Subject
