import { ReactElement } from "react"

const RadioButtonChecked = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1477F8"
        d="M12 7a5 5 0 100 10 5 5 0 000-10zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.422 0-8-3.578-8-8 0-4.422 3.578-8 8-8 4.422 0 8 3.578 8 8 0 4.422-3.578 8-8 8zm0 0"
      ></path>
    </svg>
  )
}

export default RadioButtonChecked
