import { API_URL,API_TEMPLATE_URL_GO } from "constants/config";

import axios from "axios";

const http = axios.create({
  baseURL: API_URL,
});

const httpGO = axios.create({
  baseURL: API_TEMPLATE_URL_GO
});

const isAPIGO = true


httpGO.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      //@ts-ignore
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


httpGO.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
    } else if (error.response.status === 403) {
    } else if (error.response.status === 422) {
    } else if (error.response.status === 404) {
      return error;
    } else {
      // window.location.href = "/something-went-wrong";
      return error;
    }
  }
);

http.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      //@ts-ignore
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
    } else if (error.response.status === 403) {
    } else if (error.response.status === 422) {
    } else if (error.response.status === 404) {
      return error;
    } else {
      // window.location.href = "/something-went-wrong";
      return error;
    }
  }
);


const getGO = (url: string, headers = {}, params = {}) => {
  return httpGO.get(url, {
    ...params,
    headers: {
      ...headers,
    }
  });
};

const postGO = (url: string, data: any, headers = {}, params = {}) => {
  return httpGO.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};

const removeGO = (url: string, data: any, headers = {}) => {
  return httpGO.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};

const patchGO = (url: string, data: any, headers = {}) => {
  return httpGO.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const putGO = (url: string, data: any, headers = {}) => {
  return httpGO.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  isAPIGO,
  getGO,
  postGO,
  removeGO,
  patchGO,
  putGO,
  http,
  get,
  post,
  put,
  remove,
  patch,
};

export default module;
