import { ReactElement } from "react"

const Checkbox = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1477F8"
        d="M19 3H5c-1.11 0-2 .898-2 2v14c0 1.102.89 2 2 2h14c1.11 0 2-.898 2-2V5c0-1.102-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.172l7.59-7.594L19 8zm0 0"
      ></path>
    </svg>
  )
}

export default Checkbox
