import { createElement } from "react";
import { ElementFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { ENPS_QUESTION_TYPE } from "./surveyJsConstants";
import ENPSQuestionModel from "./ENPSQuestionModel";
import ENPSSurveyQuestion from "./ENPSSurveyQuestion";

ElementFactory.Instance.registerElement(
  ENPS_QUESTION_TYPE,
  (name: string) => {
    return new ENPSQuestionModel(name);
  },
);

ReactQuestionFactory.Instance.registerQuestion(
  ENPS_QUESTION_TYPE,
  (props: any) => {
    return createElement(ENPSSurveyQuestion, props);
  },
)
