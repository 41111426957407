import { FC } from "react";

import SurveyScreen from "features/Surveys/SurveyScreen";

type SurveysPageProps = {};

const SurveysPage: FC<SurveysPageProps> = () => {
  return <SurveyScreen />;
};

export default SurveysPage;
