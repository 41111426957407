import { FC } from "react";
import { Box } from "@mui/material";

import styled from "styled-components";

type NoSurveysProps = {};

const NoSurveys: FC<NoSurveysProps> = () => {
  return (
    <Box>
      <Description>Great news! There are currently no pending surveys for your action.</Description>
      <Text>
        We look forward to your continued support in shaping the future of your workplace. Your
        valuable insights play a crucial role in guiding us forward.
      </Text>
    </Box>
  );
};

export default NoSurveys;

const Description = styled.h2`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 20px 0px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;
