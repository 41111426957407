import { Navigate, Route, Routes } from "react-router-dom";

import Page404 from "./pages/404/Page404";
import AppWrapper from "./AppWrapper";
import PrivateRoute from "./PrivateRoute";
import Error403Page from "pages/403/index";
import SurveysPage from "pages/SurveysPage/SurveysPage";
import SurveyReponsePage from "pages/SurveyReponsePage/SurveyReponsePage";
import AccessUsingUniqueIdPage from "pages/AccessUsingUniqueIdPage/AccessUsingUniqueIdPage";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<AppWrapper />}>
            <Route path="/" element={<Navigate to="/access-using-unique-id" />} />
            <Route path="/access-using-unique-id/:entity_id" element={<AccessUsingUniqueIdPage />} />
            <Route path="/surveys/:guid" element={<SurveysPage />} />
            <Route path="/surveys-unique/:unique/:entity_id" element={<SurveysPage />} />
            <Route path="/survey-response/:guid" element={<SurveyReponsePage />} />
          </Route>
        </Route>
        <Route path="*" element={<Page404 />} />
        <Route path="/access-deniend" element={<Error403Page />} />
      </Routes>
    </div>
  );
};

export default App;
