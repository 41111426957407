import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import dayjs from "dayjs";
import store from "./store";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type SurveyListProps = {};

const SurveyList: FC<SurveyListProps> = observer(() => {
  const navigate = useNavigate();
  return (
    <Box>
      <Description>Pending surveys</Description>
      <Text>Here’s a list of surveys awaiting your input. Dive in and share your thoughts!</Text>
      {store.surveysList.length > 0 && (
        <List>
          <ListTitle>Surveys</ListTitle>
          {store.surveysList.map((survey) => (
            <Link to={`/survey-response/${survey.guid}`} key={survey.id_survey}>
              <ListItem key={survey.id_survey}>
                <Box>
                  <ItemHeader>
                    <ItemName>
                      {survey.title}{" "}
                      <HeaderLabel>Est. time {survey.estimation}</HeaderLabel>
                    </ItemName>
                  </ItemHeader>

                  {(survey.min_responses === 0 || survey.min_responses === 1) && (
                    <ConfidentionalBox>
                      <ConfidentionalText>This survey is non-confidential.</ConfidentionalText>
                    </ConfidentionalBox>
                  )}

                  <BottomLabel>
                    Invite sent on {dayjs(survey.invite_sent).format("D MMMM YYYY")}
                  </BottomLabel>
                </Box>
                <GoToButton onClick={() => navigate(`/survey-response/${survey.guid}`)}>
                  <KeyboardArrowRightIcon />
                </GoToButton>
              </ListItem>
            </Link>

          ))}
        </List>
      )}
      {store.reviewsList.length > 0 && (
        <List>
          <ListTitle>Reviews</ListTitle>
          {store.reviewsList.map((review) => (
            <ListItem key={review.id}>
              <Box>
                <ItemHeader>
                  <ItemName>
                    {review.name} <HeaderLabel>{review.time}</HeaderLabel>
                  </ItemName>
                </ItemHeader>
                <BottomLabel>Invite sent on {review.inviteDate}</BottomLabel>
              </Box>
              <GoToButton>
                <KeyboardArrowRightIcon />
              </GoToButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
});

export default SurveyList;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const ListTitle = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const ListItem = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--graySelectBoxBorder);
  margin-bottom: 10px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 25px;
  column-gap: 25px;

  @media (max-width: 768px) {
    column-gap: 10px;
  }
`;

const ItemName = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-left: 6px;
  margin-bottom: 1px;

  @media (max-width: 768px) {
    margin: 4px 0px 0px 0px;
  }
`;

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const GoToButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colorNeutralForeground2);
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s;

  svg {
    color: var(--colorNeutralForeground2);
    font-size: 18px;
  }

  &:hover {
    background-color: var(--colorNeutralBackground3);
  }
`;

const ConfidentionalBox = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: var(--colorPaletteBlueBackground1);
  padding: 10px;
  margin: 6px 0px 10px 0px;
`;

const ConfidentionalText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const ItemDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-top: 6px;
`;

const BottomLabel = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground5);
  margin-top: 10px;
`;

const Description = styled.h2`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 20px 0px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;
