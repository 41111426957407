import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ReactDOM from "react-dom/client";

import "./index.css";
import "./assets/styles/variables.css";
import 'survey-core/defaultV2.min.css';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
