export type SurveyJson = Record<string, unknown>
export type QuestionnaireResponseJson = Record<string, unknown>

export type QuestionnaireQuestionMetric = {
  createdAt: string
  id: string
  identifier: string
  name: string
  questionnaireID: string
  updatedAt: string
}

export enum QuestionType {
  comment = "comment",
  rating = "rating",
  checkbox = "checkbox",
  boolean = "boolean",
  radiogroup = "radiogroup",
  enps = "eNPS",
}

export type QuestionnaireQuestion = {
  createdAt: string
  id: string
  orderIndex: number
  metrics?: QuestionnaireQuestionMetric[]
  name: string
  questionnaireID: string
  title: string
  type: QuestionType
  updatedAt: string
  visibleIf?: string
}

export enum QuestionnaireStatus {
  Pending = "pending",
  Open = "open",
  Closed = "closed",
}

export enum QuestionnaireResponseStatus {
  Pending = "pending",
  Submitted = "submitted",
}

export type Questionnaire = {
  id: string
  surveyJSBlob: SurveyJson
  status: QuestionnaireStatus
  orgEntityName: string
  surveyId: number
  createdAt: string
  updatedAt: string
}

export type EmployeeAttribute = {
  name: string
  values: string[]
}

export type UserAttribute = {
  name: string
  value: string
}

export interface dictionary_language {
  id: number;
  name: string;
  code: string;
  queueNumber: string;
  is_default: boolean;
  iso: string;
}
